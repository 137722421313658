@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:wght@700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto+Mono:wght@500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap');

.product-card video {
  object-fit: cover;
  width: 100%;
  height: 200px; /* Adjust as needed */
  background-color: #f0f0f0; /* Placeholder background color */
}

.video-wrapper {
  max-width: 100%;
  margin-bottom: 20px;
}

.video-wrapper video {
  width: 100%;
  height: auto;
}

.tags {
  margin-top: 10px;
}

.tag {
  display: inline-block;
  background-color: #f0f0f0;
  padding: 5px 10px;
  margin-right: 5px;
  margin-bottom: 5px;
  border-radius: 3px;
  font-size: 0.9em;
}

.main-header {
    display: flex;
    justify-content: flex-start; /* Changed from space-between to flex-start */
    align-items: center;
    padding: 1rem 1rem; /* Reduced horizontal padding */
    background-color: #f8f9fa;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.logo-container {
    flex: 0 0 auto;
    margin-right: 1rem; /* Add some space to the right of the logo */
}

.logo {
    width: 70px; /* Increased size from 60px to 70px */
    height: 70px; /* Increased size from 60px to 70px */
    border-radius: 50%; /* Makes the logo rounded */
    object-fit: cover; /* Ensures the image fills the circular container */
    border: 2px solid #4a90e2; /* Adds a border around the logo */
}

.site-title {
    flex: 1 1 auto;
    text-align: left;
    font-family: 'Playfair Display', serif;
    font-size: 2.5rem;
    margin: 0;
    padding: 5px 10px;
    border-radius: 8px;
    display: inline-block;
}

.site-title span {
    display: inline-block;
    animation: dance 2s infinite, colorChange 5s infinite;
}

.site-title span:nth-child(2n) {
    animation-delay: 0.1s, 0.5s;
}

.site-title span:nth-child(3n) {
    animation-delay: 0.2s, 1s;
}

/* Target the space after "l" in "Special" */
.site-title span:nth-child(7) {
    margin-right: -0.1em; /* Adjust this value as needed to remove the extra space */
}

/* Add space between "Special" and "Offers" */
.site-title span:nth-child(8) {
    margin-left: 0.2em; /* Adjust this value to increase or decrease the space */
}

@keyframes dance {
    0%, 100% {
        transform: translateY(0);
    }
    25% {
        transform: translateY(-5px) rotate(-5deg);
    }
    75% {
        transform: translateY(5px) rotate(5deg);
    }
}

@keyframes colorChange {
    0%, 100% { color: #4a90e2; }
    20% { color: #e74c3c; }
    40% { color: #f39c12; }
    60% { color: #2ecc71; }
    80% { color: #9b59b6; }
}

.site-title:hover span {
    animation: danceFast 0.5s infinite, colorChangeFast 2s infinite;
}

@keyframes danceFast {
    0%, 100% {
        transform: translateY(0) rotate(0);
    }
    50% {
        transform: translateY(-3px) rotate(3deg);
    }
}

@keyframes colorChangeFast {
    0%, 100% { color: #4a90e2; }
    25% { color: #e74c3c; }
    50% { color: #f39c12; }
    75% { color: #2ecc71; }
}

.main-nav {
    flex: 0 0 auto;
    margin-left: auto; /* Push the nav to the right */
}

.main-nav ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
    display: flex;
}

.main-nav li {
    margin-left: 1rem;
}

.main-nav a,
.logout-btn {
    text-decoration: none;
    color: #333;
    font-weight: bold;
    padding: 0.5rem 1rem;
    border-radius: 4px;
    transition: background-color 0.3s ease;
}

.main-nav a:hover,
.logout-btn:hover {
    background-color: #e9ecef;
}

.logout-btn {
    background: none;
    border: none;
    cursor: pointer;
    font-size: 1rem;
}

.logo-container a {
    display: block;
    transition: transform 0.3s ease;
}

.logo-container a:hover {
    transform: scale(1.1);
}

.product-card {
    border-radius: 8px;
    overflow: hidden;
    background: var(--card-background);
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    position: relative;
    animation: cardFloat 5s ease-in-out infinite;
}

@keyframes cardFloat {
    0%, 100% {
        transform: translateY(0);
    }
    50% {
        transform: translateY(-10px);
    }
}

.product-card:hover {
    transform: translateY(-5px) scale(1.03);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
    animation: cardPulse 0.5s ease-in-out infinite alternate;
}

@keyframes cardPulse {
    from {
        box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
    }
    to {
        box-shadow: 0 12px 20px rgba(74, 144, 226, 0.3);
    }
}

.product-card .media-container {
    position: relative;
    width: 100%;
    height: 220px;
    overflow: hidden;
}

.product-card .media-container img,
.product-card .media-container .video-wrapper {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: transform 0.3s ease;
}

.product-card:hover .media-container img,
.product-card:hover .media-container .video-wrapper {
    transform: scale(1.1);
}

.product-card .product-code {
    position: absolute;
    top: 10px;
    right: 10px;
    background-color: rgba(255, 255, 255, 0.8);
    color: #4a90e2;
    padding: 5px 10px;
    border-radius: 15px;
    font-family: 'Roboto Mono', monospace;
    font-size: 0.75rem;
    font-weight: 500;
    letter-spacing: 0.5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease;
}

.product-card:hover .product-code {
    background-color: #4a90e2;
    color: white;
    transform: scale(1.05);
}

.product-card h3 {
    font-size: 0.8rem;
    line-height: 1.2;
    margin: 0.5rem;
    color: var(--primary-color);
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    transition: color 0.3s ease;
}

.product-card:hover h3 {
    color: #3a7bc8;
}

.product-card .price {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(255, 255, 255, 0.9);
    padding: 0.5rem;
    font-size: 1rem;
    font-weight: bold;
    color: var(--secondary-color);
    opacity: 0;
    transition: opacity 0.3s ease, transform 0.3s ease;
    text-align: center;
    transform: translateY(100%);
}

.product-card:hover .price {
    opacity: 1;
    transform: translateY(0);
}

/* Login Page Styles */
.login-container {
    display: flex;
    justify-content: center;
    align-items: flex-start; /* Changed from center to flex-start */
    min-height: calc(100vh - 100px); /* Changed from height to min-height */
    padding-top: 2rem; /* Added padding to give some space from the top */
    background-color: #f8f9fa;
}

.login-form {
    background-color: white;
    padding: 2rem;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    width: 100%;
    max-width: 400px;
}

.login-form h2 {
    text-align: center;
    color: #4a90e2;
    margin-bottom: 1.5rem;
    font-family: 'Playfair Display', serif;
}

.form-group {
    margin-bottom: 1rem;
}

.form-group label {
    display: block;
    margin-bottom: 0.5rem;
    color: #333;
    font-weight: bold;
}

.form-group input {
    width: 100%;
    padding: 0.5rem;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 1rem;
}

.login-button {
    width: 100%;
    padding: 0.75rem;
    background-color: #4a90e2;
    color: white;
    border: none;
    border-radius: 4px;
    font-size: 1rem;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.login-button:hover {
    background-color: #3a7bc8;
}

.error-message {
    color: #e74c3c;
    text-align: center;
    margin-bottom: 1rem;
}

.search-container {
    text-align: center;
    margin: 1rem 0;
}

.search-hint {
    font-family: 'Roboto', sans-serif;
    font-size: 0.8rem;
    color: #6c757d;
    margin-top: 0.5rem;
    font-style: italic;
    letter-spacing: 0.5px;
    text-transform: lowercase;
    opacity: 0.8;
    transition: opacity 0.3s ease;
}

.search-container:hover .search-hint {
    opacity: 1;
}

.main-footer {
    background-color: #f8f9fa;
    color: #333;
    text-align: center;
    padding: 1rem;
    margin-top: 2rem;
    border-top: 1px solid #e9ecef;
}

.main-footer p {
    margin: 0.5rem 0;
}

.main-footer .copyright {
    font-family: 'Roboto', sans-serif;
    font-weight: 700;
    font-size: 0.9rem;
    color: #4a90e2;
}

.main-footer .disclaimer {
    font-family: 'Roboto', sans-serif;
    font-weight: 400;
    font-style: italic;
    font-size: 0.8rem;
    color: #6c757d;
    max-width: 600px;
    margin-left: auto;
    margin-right: auto;
}

.analytics-dashboard {
  margin-top: 2rem;
}

.chart-container {
  margin-bottom: 2rem;
}

.chart-container h3 {
  margin-bottom: 1rem;
}