/* index.css */

:root {
    --primary-color: #6C63FF;  /* Trendy purple */
    --secondary-color: #FF6584; /* Coral pink */
    --background-color: #F9F9F9; /* Light gray */
    --text-color: #333333; /* Dark gray */
    --card-background: #FFFFFF; /* White */
    --accent-color: #4CAF50; /* Green */
}

/* Apply a basic reset */
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

/* Set up basic styles for the body */
body {
    font-family: 'Roboto', Arial, sans-serif;
    line-height: 1.6;
    background-color: var(--background-color);
    color: var(--text-color);
}

/* Style the container */
.container {
    width: 90%;
    max-width: 1200px;
    margin: auto;
    overflow: hidden;
}

/* Style the header */
header {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 1rem;
    background-color: var(--card-background);
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    margin-bottom: 2rem;
}

.logo {
    width: 30px;
    height: 30px;
    margin-bottom: 0.5rem;
    animation: spin 20s linear infinite alternate;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(720deg);
    }
}

h1 {
    color: var(--primary-color);
    font-size: 1.4rem; /* Reduced from 1.5rem */
    margin-bottom: 0.5rem;
}

.main-nav {
    width: 100%;
}

.main-nav ul {
    display: flex;
    justify-content: center;
    list-style-type: none;
    gap: 1rem;
}

.main-nav a, .logout-btn {
    text-decoration: none;
    color: var(--text-color);
    font-weight: bold;
    padding: 0.5rem 1rem;
    border-radius: 20px;
    transition: background-color 0.3s ease, color 0.3s ease;
}

.main-nav a:hover, .logout-btn:hover {
    background-color: var(--primary-color);
    color: white;
}

.logout-btn {
    background: none;
    border: none;
    cursor: pointer;
    font-size: 1rem;
}

/* Style the search input container */
.search-container {
    text-align: center;
    margin: 1rem 0;
}

.search-container input {
    width: 100%;
    max-width: 600px;
    padding: 0.75rem;
    border: 1px solid var(--primary-color);
    border-radius: 25px;
    font-size: 1rem;
    transition: all 0.3s ease;
}

.search-container input:focus {
    box-shadow: 0 0 5px rgba(108, 99, 255, 0.5);
    outline: none;
    border-color: var(--primary-color);
}

/* Add this new style for the placeholder animation */
@keyframes cyclePlaceholder {
    0%, 100% { opacity: 0; }
    20%, 80% { opacity: 1; }
}

.search-container input::placeholder {
    animation: cyclePlaceholder 4s ease-in-out infinite;
}

/* Grid layout for product cards */
#products {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    gap: 1rem;
    padding: 1rem;
    min-height: 300px; /* Ensure there's enough height for centering */
}

.no-products {
    grid-column: 1 / -1; /* Span all columns */
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    font-size: 1.2rem;
    color: var(--text-color);
    text-align: center;
    padding: 2rem;
}

/* Style the product cards */
.product-card {
    border-radius: 8px;
    overflow: hidden;
    background: var(--card-background);
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    position: relative;
}

.product-card:hover {
    transform: translateY(-3px);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
}

.product-card a {
    text-decoration: none;
    color: inherit;
    display: block;
    height: 100%;
}

.product-card img {
    width: 100%;
    height: 150px;
    object-fit: cover;
}

.product-card h3 {
    font-size: 0.8rem;
    line-height: 1.2;
    margin: 0.5rem;
    color: var(--primary-color);
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

.product-card .price {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(255, 255, 255, 0.9);
    padding: 0.5rem;
    font-size: 1rem;
    font-weight: bold;
    color: var(--secondary-color);
    opacity: 0;
    transition: opacity 0.3s ease;
    text-align: center;
}

.product-card:hover .price {
    opacity: 1;
}

.product-card .price::after {
    content: "*Price may vary";
    display: block;
    font-size: 0.6rem;
    font-weight: normal;
    color: var(--text-color);
    opacity: 0.7;
    font-style: italic;
    margin-top: 0.2rem;
}

/* Style the buttons */
button {
    padding: 0.5rem 1rem;
    border: none;
    border-radius: 20px;
    background-color: var(--primary-color);
    color: white;
    cursor: pointer;
    font-size: 1rem;
    transition: background-color 0.3s ease;
}

button:hover {
    background-color: var(--secondary-color);
}

/* Style the tags */
#tags {
    text-align: center;
    margin: 0.8rem 0;  /* Reduced from 1rem */
}

#tags button {
    margin: 0.2rem;  /* Reduced from 0.3rem */
    padding: 0.3rem 0.6rem;  /* Reduced from 0.4rem 0.8rem */
    border: none;
    background-color: var(--secondary-color);
    color: white;
    cursor: pointer;
    transition: background-color 0.3s ease;
    animation: tagBounce 1s ease-in-out infinite;
    font-size: 0.8rem;  /* Reduced from 0.9rem */
    border-radius: 15px;  /* Added to make corners more rounded */
}

#tags button:hover {
    background-color: var(--primary-color);
}

@keyframes tagBounce {
    0%, 100% {
        transform: translateY(0);
    }
    50% {
        transform: translateY(-3px);  /* Reduced from -5px */
    }
}

/* Add this new rule to create a staggered animation effect */
#tags button:nth-child(odd) {
    animation-delay: 0.5s;
}

/* Style the load more button container */
.load-more-container {
    text-align: center;
    margin: 1rem 0;
}

/* Add responsive design */
@media (max-width: 1200px) {
    #products {
        grid-template-columns: repeat(auto-fill, minmax(180px, 1fr));
    }
}

@media (max-width: 992px) {
    #products {
        grid-template-columns: repeat(3, 1fr);
    }
}

@media (max-width: 768px) {
    #products {
        grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
    }
}

@media (max-width: 480px) {
    #products {
        grid-template-columns: repeat(1, 1fr);
    }
}

/* Add these rules to your existing CSS */

/* Ensure proper sizing on mobile devices */
html {
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
}

/* Adjust font sizes for mobile */
@media (max-width: 768px) {
    body {
        font-size: 14px;
    }

    h1 {
        font-size: 1.7rem; /* Reduced from 1.8rem */
    }

    h2 {
        font-size: 1.5rem;
    }

    h3 {
        font-size: 1.2rem;
    }
}

/* Ensure touch targets are large enough */
@media (max-width: 768px) {
    button, 
    .main-nav a, 
    .logout-btn {
        min-height: 44px;
        min-width: 44px;
    }

    input[type="text"],
    input[type="search"] {
        height: 44px;
    }
}

/* iPhone-specific adjustments */
@media only screen 
    and (min-device-width: 375px) 
    and (max-device-width: 812px) 
    and (-webkit-min-device-pixel-ratio: 3) {
    .container {
        padding-left: 15px;
        padding-right: 15px;
    }

    .product-card {
        margin-bottom: 15px;
    }
}

/* Ensure the move-up button doesn't overlap with content on smaller screens */
@media (max-width: 768px) {
    .move-up-btn {
        bottom: 60px; /* Adjust this value as needed */
    }
}

/* Add this to your existing CSS */
.lazy-load-image-background.blur {
    filter: blur(15px);
}

.lazy-load-image-background.blur.lazy-load-image-loaded {
    filter: blur(0);
    transition: filter 0.3s;
}

.product-card video {
    object-fit: cover;
    width: 100%;
    height: 200px; /* Adjust as needed */
    background-color: #f0f0f0; /* Placeholder background color */
}

/* Add this to your existing CSS */
.video-wrapper {
    position: relative;
    width: 100%;
    height: 150px; /* Match the height of product images */
    overflow: hidden;
}

.video-wrapper video {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.product-card img,
.product-card .video-wrapper {
    width: 100%;
    height: 220px;
    object-fit: cover;
}

.product-card .video-wrapper {
    position: relative;
    width: 100%;
    padding-top: 100%; /* 1:1 Aspect Ratio */
}

.product-card .video-wrapper video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.product-card .react-player {
    position: absolute !important;
    top: 0 !important;
    left: 0 !important;
    width: 100% !important;
    height: 100% !important;
}

/* Add this new style */
.react-player__preview {
    width: 100% !important;
    height: 100% !important;
    background-size: cover !important;
}

.debug-info, .debug-product-info {
    background-color: rgba(0, 0, 0, 0.7);
    color: white;
    padding: 5px;
    font-size: 10px;
    word-break: break-all;
    z-index: 10;
}

.debug-info {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
}

.debug-product-info {
    margin-top: 10px;
}

.debug-product-info p {
    margin: 2px 0;
}

.media-preview {
    position: relative;
    width: 100%;
    padding-top: 100%; /* 1:1 Aspect Ratio */
    background-color: #f0f0f0;
    overflow: hidden;
}

.media-preview img,
.media-preview .video-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.video-container {
    display: flex;
    justify-content: center;
    align-items: center;
}

.video-container .video-thumbnail {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.video-container video {
    display: none;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.video-container:hover .video-thumbnail {
    display: none;
}

.video-container:hover video {
    display: block;
}

.media-error,
.loading {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.1);
    color: #333;
    font-size: 14px;
    z-index: 2;
}

/* Prevent unwanted focus on mobile devices */
@media (max-width: 768px) {
    input, textarea, button, select, a {
        -webkit-tap-highlight-color: rgba(0,0,0,0);
    }
}

button:disabled {
    opacity: 0.5;
    cursor: not-allowed;
}